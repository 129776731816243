import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isPlainObject from 'lodash/isPlainObject';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';

export const can = (permission, object) => get(object, ['currentUserMetadata', 'permissions'], []).includes(permission);

export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        [camelCase(key)]: camelizeKeys(obj[key]),
        ...result,
      }),
      {},
    );
  }
  return obj;
};

export const snakifyKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => snakifyKeys(v));
  } else if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        [snakeCase(key)]: snakifyKeys(obj[key]),
        ...result,
      }),
      {},
    );
  }
  return obj;
};

export const numberWithCommas = (n, {round} = {round: false}) => (round ? Math.round(n) : n).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const abbreviateBigNumber = (n) => {
  if (n > 1000000) return `${Math.round(n / 100000) / 10}M`;
  if (n > 1000) return `${Math.round(n / 100) / 10}K`;
  else return n;
};

export const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

export const isServer = typeof window === 'undefined';

export const isClient = !isServer;

function ObserverShim(_callback, {shim = {}} = {}) {
  this.observe = shim.observe || noop;
  this.unobserve = shim.unobserve || noop;
  this.disconnect = shim.disconnect || noop;
}

export const IntersectionObserver = (isClient && 'IntersectionObserver' in window) ?
  window.IntersectionObserver : ObserverShim;

export const ResizeObserver = (isClient && 'ResizeObserver' in window) ?
  window.ResizeObserver : ObserverShim;

export const waitUntil = (condition, timeout = 10000, interval = 50) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      clearInterval(wait);
      reject(new Error(`Timed out waiting for condition: ${condition}`));
    }, timeout);

    const wait = setInterval(() => {
      try {
        if (!condition()) return;
        clearInterval(wait);
        clearTimeout(timeout);
        resolve();
      } catch (error) {
        clearInterval(wait);
        clearTimeout(timeout);
        reject(error);
      }
    }, interval);
  })
);

export const waitForGlobal = (name, timeout = 10000, interval = 100) =>
  waitUntil(() => window[name], timeout, interval);

export const queryString = (obj) => {
  const queries = Object.keys(obj).reduce(
    (result, key) => (
      result.concat(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    ),
    [],
  );
  return queries.join('&');
};

export const ALERT_TYPES = ['success', 'error', 'warning'];

const computeIntersectionRect = (rect1, rect2) => {
  const top = Math.max(rect1.top, rect2.top);
  const bottom = Math.min(rect1.bottom, rect2.bottom);
  const left = Math.max(rect1.left, rect2.left);
  const right = Math.min(rect1.right, rect2.right);
  const width = right - left;
  const height = bottom - top;

  return (width >= 0 && height >= 0) ? {width, height} : {width: 0, height: 0};
};

export const computeIntersectionRatio = (rect1, rect2) => {
  const intersection = computeIntersectionRect(rect1, rect2);
  return (intersection.width * intersection.height) / (rect2.width * rect2.height);
};

const SURROGATE_PAIR = '[\\uD800-\\uDBFF][\\uDC00-\\uDFFF]';
const ANY_CHARACTER = '[\\u0000-\\uFFFF]';
const JAVASCRIPT_SYMBOL = new RegExp(`${SURROGATE_PAIR}|${ANY_CHARACTER}`, 'g');
export const stringToSymbolArray = string => string.match(JAVASCRIPT_SYMBOL) || [];

export const numberWithSign = number => number >= 0 ? `+${number}` : String(number);

export const elementInViewport = (element) => {
  if (!element) return false;

  const bounding = element.getBoundingClientRect();
  const elementHeight = element.offsetHeight;
  const elementWidth = element.offsetWidth;

  return (
    bounding.top >= -elementHeight &&
    bounding.left >= -elementWidth &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + elementWidth &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + elementHeight
  );
};

export const flattenObject = (obj, separator = '.') =>
  Object.entries(obj).reduce((flatObj, [key, value]) => {
    if (isNil(value)) {
      return flatObj;
    }

    if (typeof value === 'object') {
      Object.entries(flattenObject(value, separator)).forEach(([innerKey, innerValue]) => {
        flatObj[`${key}${separator}${innerKey}`] = innerValue;
      });
    } else {
      flatObj[key] = value;
    }

    return flatObj;
  }, {});

export const scrollToTop = (e) => {
  e.preventDefault();
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

export const arrayToSentence = (array) => {
  if (!array.length) return '';
  if (array.length === 1) return array[0].toString();

  return [
    array.slice(0, -1).join(', '),
    array.slice(-1)[0],
  ].join(' & ');
};
