/* eslint-disable react/no-multi-comp */
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import styled, {useTheme} from 'styled-components';
import Select, {components} from 'react-select';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';

import DownArrowFilled from '@/svg/downArrowFilled.svg';

import {reactSelectInputStyles, reactSelectTheme, ClearIndicator} from './Input.shared';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <DownArrowFilled />
  </components.DropdownIndicator>
);

const SelectInput = ({
  className,
  dataTestId,
  defaultValue,
  disabled,
  isClearable,
  onChange,
  options,
  placeholder,
  controlledValue,
  customStyles,
}) => {
  const theme = useTheme();
  const [selected, setSelected] = useState(defaultValue);

  const onSelectChange = useCallback((selectedOption) => {
    setSelected(selectedOption);

    if (onChange) {
      onChange(selectedOption);
    }
  }, [onChange]);

  useEffect(() => {
    if (typeof controlledValue !== undefined && !isEqual(selected, controlledValue)) {
      setSelected(controlledValue);
    }
  }, [selected, controlledValue]);

  const optionLabels = useMemo(() => keyBy(options, 'label'), [options]);
  const onBlur = useCallback((event) => {
    const blurValue = event.target.value;
    if (optionLabels[blurValue]) {
      onSelectChange(optionLabels[blurValue]);
    }
  }, [optionLabels, onSelectChange]);

  const styles = useMemo(() => reactSelectInputStyles(theme), [theme]);

  return (
    <SelectInput.Container className={className} data-testid={dataTestId}>
      <Select
        placeholder={placeholder}
        theme={reactSelectTheme(theme)}
        menuPlacement="auto"
        isMulti={false}
        isClearable={isClearable}
        isDisabled={disabled}
        value={selected}
        styles={{
          ...styles,
          ...customStyles,
        }}
        options={options}
        components={{
          DropdownIndicator,
          ClearIndicator,
        }}
        onChange={onSelectChange}
        onBlur={onBlur}
      />
    </SelectInput.Container>
  );
};

SelectInput.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  placeholder: PropTypes.string,
  controlledValue: PropTypes.any,
  customStyles: PropTypes.object,
};

SelectInput.defaultProps = {
  isClearable: false,
  disabled: false,
};

export default SelectInput;

SelectInput.Container = styled.div`
  color: ${p => p.theme.color.background.on};
  font-family: ${p => p.theme.font.alternate};
  svg {
    fill: black;
    height: 11px;
  }
`;
