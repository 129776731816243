import {useCallback, useState, useEffect} from 'react';

export default function useLocalStorage(key, initialValue) {
  const KEY_WITH_PREFIX = `com.Genius.useLocalStorage.${key}`;

  const readValue = useCallback(() => {
    if (typeof window === 'undefined') {
      return initialValue instanceof Function ? initialValue() : initialValue;
    }

    try {
      const item = window.localStorage.getItem(KEY_WITH_PREFIX);
      if (item === null) {
        return initialValue instanceof Function ? initialValue() : initialValue;
      }
      return JSON.parse(item);
    } catch (error) {
      return initialValue instanceof Function ? initialValue() : initialValue;
    }
  }, [initialValue, KEY_WITH_PREFIX]);

  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  const setValue = useCallback(
    (value) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        if (typeof window !== 'undefined') {
          window.localStorage.setItem(KEY_WITH_PREFIX, JSON.stringify(valueToStore));
        }
      } catch (_ignored) {
      }
    },
    [KEY_WITH_PREFIX, storedValue],
  );

  return [storedValue, setValue];
}
