export const REFERENTS_BATCH_REQUESTED = 'songPage/REFERENTS_BATCH_REQUESTED';
export const referentsBatchRequested = ({ids}) => ({
  type: REFERENTS_BATCH_REQUESTED,
  payload: {
    ids,
  },
});

export const REFERENTS_BATCH_FETCHED = 'songPage/REFERENTS_BATCH_FETCHED';
export const referentsBatchFetched = ({entities}) => ({
  type: REFERENTS_BATCH_FETCHED,
  entities,
});
