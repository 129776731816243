import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import {abbreviateBigNumber} from '@/util';

const LabelWithIcon = ({className, disabled, height, icon, label, labelSize, onClick, showZero, underline, vertical, gap, title}) => {
  const abbreviateNumber = number => number === 0 && !showZero ? null : abbreviateBigNumber(number);

  return (
    <LabelWithIcon.Container
      as={Boolean(onClick) ? 'button' : 'span'}
      className={className}
      clickable={Boolean(onClick)}
      disabled={disabled}
      height={height}
      onClick={onClick}
      vertical={vertical}
      gap={gap}
      title={title}
    >
      <div aria-hidden>
        {icon}
      </div>
      {!isNil(label) && (
        <LabelWithIcon.Label clickable={Boolean(onClick)} vertical={vertical} underline={underline} labelSize={labelSize}>
          {isNumber(label) ? abbreviateNumber(label) : label }
        </LabelWithIcon.Label>
      )}
    </LabelWithIcon.Container>
  );
};

LabelWithIcon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  icon: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelSize: PropTypes.string,
  onClick: PropTypes.func,
  showZero: PropTypes.bool,
  underline: PropTypes.bool,
  vertical: PropTypes.bool,
  gap: PropTypes.string,
  title: PropTypes.string,
};

LabelWithIcon.defaultProps = {
  height: '1em',
  labelSize: 'smallReading',
  showZero: true,
  underline: true,
  vertical: false,
  gap: 'quarter',
};

export default React.memo(LabelWithIcon);

LabelWithIcon.Container = styled.div`
  display: flex;
  align-items: center;
  font-size: ${p => p.theme.fontSize.smallReading};
  font-family: ${p => p.theme.font.reading};
  color: ${p => p.theme.color.background.on};
  flex-direction: ${p => p.vertical ? 'column' : 'row'};
  gap: ${p => p.theme.space[p.gap]};

  svg {
    display: block;
    height: ${p => p.height};
    fill: ${p => p.disabled ? p.theme.color.background.onVariant : p.theme.color.background.on};
  }

  &:disabled {
    color: ${p => p.theme.color.background.onVariant};
  }
`;

LabelWithIcon.Label = styled.span`
  text-decoration: ${p => p.clickable || p.underline ? 'underline' : 'none'};
  font-weight: ${p => p.theme.fontWeight.light};
  font-size: ${p => p.theme.fontSize[p.labelSize]};
  color: inherit;

  &:hover {
    ${p => p.underline && 'text-decoration: none;'}
  }
`;
