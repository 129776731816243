import React from 'react';
import {isClient} from '@/util';
import {AppConfig} from '@/config';

const IGNORED_ERROR_MESSAGES = [/bids is undefined/];

let bugsnagClient;
if (isClient && AppConfig.reactBugsnagApiKey) {
  const bugsnag = require('@bugsnag/js');
  const bugsnagReact = require('@bugsnag/plugin-react');
  const config = {
    apiKey: AppConfig.reactBugsnagApiKey,
    appVersion: AppConfig.releaseVersion,
    releaseStage: AppConfig.env,
    beforeSend(report) {
      if (IGNORED_ERROR_MESSAGES.some(ignoredMsg => report.errorMessage.match(ignoredMsg))) {
        return false;
      }
    },
  };
  const currentUserId = window.__PRELOADED_STATE__.session.currentUser;
  if (currentUserId) {
    config.user = {id: currentUserId};
  }
  config.metaData = {
    genius: {
      affectsLoggedIn: Boolean(currentUserId),
    },
  };
  bugsnagClient = bugsnag(config);
  bugsnagClient.use(bugsnagReact, React);
} else {
  bugsnagClient = {
    use() {},
    notify: console.error, // eslint-disable-line no-console
    leaveBreadcrumb: () => {},
    getPlugin: () => React.Fragment,
  };
}

export default bugsnagClient;
