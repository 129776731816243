import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {TextLabel} from '@/components';
import {InboxUnreadCount} from '@/components/styleAnchors';

const PageHeaderInboxUnreadCount = ({unreadCount, abbreviateAfter}) => {
  if (unreadCount <= 0) {
    return null;
  }

  return (
    <PageHeaderInboxUnreadCount.Container>
      <TextLabel color="accent.on">{abbreviateAfter && unreadCount > abbreviateAfter ? `${abbreviateAfter}+` : unreadCount}</TextLabel>
    </PageHeaderInboxUnreadCount.Container>
  );
};

PageHeaderInboxUnreadCount.propTypes = {
  unreadCount: PropTypes.number.isRequired,
  abbreviateAfter: PropTypes.number,
};

export default React.memo(PageHeaderInboxUnreadCount);

PageHeaderInboxUnreadCount.Container = styled(InboxUnreadCount)`
  position: absolute;
  background: ${p => p.theme.color.accent.main};
  border-radius: 18px;
  padding: 0 .2rem;
  line-height: 1;
  height: 18px;
  min-width: 18px;
  left: 18px;
  top: 4px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${p => p.theme.font.reading};
`;
