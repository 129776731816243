import React, {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const avoidScrollPositionJumping = (resizeFunction) => {
  const htmlTop = window.scrollY;
  const bodyTop = document.body.scrollTop;
  resizeFunction();
  window.scrollTo({top: htmlTop});
  document.body.scrollTop = bodyTop;
};

const ExpandingTextarea = ({defaultValue, ...props}, ref) => {
  const resize = useCallback(() => {
    avoidScrollPositionJumping(() => {
      const el = ref.current;
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
    });
  }, [ref]);

  useEffect(resize, [defaultValue, resize]);

  return (
    <ExpandingTextarea.Textarea
      ref={ref}
      {...props}
      defaultValue={defaultValue}
      onChange={resize}
    />
  );
};

export default React.memo(React.forwardRef(ExpandingTextarea));

ExpandingTextarea.propTypes = {
  defaultValue: PropTypes.string,
};

ExpandingTextarea.Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  font-family: inherit;
  resize: none;
  overflow-y: hidden;
  min-height: 18.75rem;
  padding: ${p => p.theme.space.full};
  font-size: ${p => p.theme.fontSize.largeReading};
  font-weight: ${p => p.theme.fontWeight.light};
  border-radius: 0;
  line-height: ${p => p.theme.lineHeight.reading};
`;
