import React, {useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {shallowEqual} from 'react-redux';
import isPlainObject from 'lodash/isPlainObject';

import {
  FieldContainer,
  FieldError,
  FieldHelpText,
  FieldLabel,
  FieldControlWithLabel,
} from './Field.shared';

const Field = ({
  children,
  className,
  defaultValue,
  disabled = false,
  helpText,
  hideLabel = false,
  inlineLabel = false,
  label,
  onChangeValue,
  floatingLabel = false,
  wrapWithLabel = true,
  name,
  id,
  rules = {},
  transformForForm,
  transformForInput,
}) => {
  if (!wrapWithLabel && !(id || hideLabel)) {
    // eslint-disable-next-line no-console
    console.warn('When disabling `wrapWithLabel`, you must give the field an ID or a hidden label instead to maintain accessibility');
  }

  const containerElementType = wrapWithLabel ? 'label' : 'div';
  const labelElementType = wrapWithLabel ? 'span' : 'label';

  const defaultTransform = useCallback(value => value, []);
  const runTransformForForm = transformForForm || defaultTransform;
  const runTransformForInput = transformForInput || defaultTransform;

  const isRequired = rules?.required && Boolean(isPlainObject(rules.required) ? rules.required.value : rules.required);
  const labelComponent = !hideLabel && (
    <FieldLabel as={labelElementType} htmlFor={id}>
      {label}&nbsp;{isRequired && '*'}
    </FieldLabel>
  );

  const firstChildrenRender = useRef(true);
  const inputDefaultValue = useRef();

  const wrappedOnChange = useCallback(field => async (e, ...rest) => {
    onChangeValue?.(e.target ? e.target.value : e, ...rest);
    field.onChange(runTransformForForm(e));
  }, [runTransformForForm, onChangeValue]);

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({field, fieldState}) => {
        if (firstChildrenRender.current) {
          inputDefaultValue.current = runTransformForInput(field.value);
          firstChildrenRender.current = false;
        }

        return (
          <FieldContainer as={containerElementType} className={className}>
            <FieldControlWithLabel inlineLabel={inlineLabel}>
              {!floatingLabel && labelComponent}
              {React.cloneElement(children, {
                ...field,
                id,
                disabled,
                defaultValue: inputDefaultValue.current,
                onChange: wrappedOnChange(field),
                hasError: fieldState.invalid,
                'aria-required': isRequired,
                ...(hideLabel && {'aria-label': `${label} ${isRequired ? '(required)' : ''}`}),
              })}
              {floatingLabel && labelComponent}
            </FieldControlWithLabel>
            {fieldState.error && (
              <FieldError>{fieldState.error.message}</FieldError>
            )}
            {helpText && (
              <FieldHelpText>{helpText}</FieldHelpText>
            )}
          </FieldContainer>
        );
      }}
    />
  );
};

const compareRules = (prevProps, nextProps) => {
  const {rules: prevRules, ...restPrevProps} = prevProps;
  const {rules: nextRules, ...restNextProps} = nextProps;
  if (!shallowEqual(prevRules, nextRules)) {
    return false;
  }

  return shallowEqual(restPrevProps, restNextProps);
};

export default React.memo(Field, compareRules);

Field.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  rules: PropTypes.object,
  hideLabel: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  wrapWithLabel: PropTypes.bool,
  transformForForm: PropTypes.func,
  transformForInput: PropTypes.func,
};
