import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {useIsMobile} from '@/hooks';

const SIDEBAR_ADS = [
  {name: 'desktop_song_lyrics_sidebar', sticky: true, lazy: false},
  {name: 'desktop_song_lyrics_sidebar2', sticky: false, lazy: true},
  {name: 'desktop_song_lyrics_sidebar3', sticky: false, lazy: true},
  {name: 'desktop_song_lyrics_sidebar4', sticky: false, lazy: true},
];

export default (body, shouldPartition) => {
  const isMobile = useIsMobile();
  const lyricsPlaceholderReason = useSelector(state => get(state, 'songPage.lyricsData.lyricsPlaceholderReason', null));

  const partitionedLyrics = useMemo(() => {
    if (lyricsPlaceholderReason || !body.children) return null;

    const [firstChild, ...rest] = body.children;
    const safeToMangle = firstChild.tag === 'p' && firstChild.children;

    if (!shouldPartition) {
      return [{lyrics: body.children}];
    }

    let sidebarAdNumber = 0;
    let currentPartition = {
      lyrics: [],
      sidebarAd: !isMobile && SIDEBAR_ADS[sidebarAdNumber++],
    };

    if (!safeToMangle) {
      currentPartition.lyrics = body.children;
      return [currentPartition];
    }

    const partitions = [];

    firstChild.children.forEach((elem) => {
      if (elem.tag === 'inread-ad') {
        partitions.push(currentPartition, {inreadAd: elem});
        currentPartition = {lyrics: [], sidebarAd: !isMobile && SIDEBAR_ADS[sidebarAdNumber++]};
      } else {
        currentPartition.lyrics.push(elem);
      }
    });

    if (currentPartition.lyrics.length || rest.length) {
      currentPartition.lyrics.push(...rest);
      partitions.push(currentPartition);
    }

    return partitions;
  }, [body, shouldPartition, isMobile, lyricsPlaceholderReason]);

  return partitionedLyrics;
};
