import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

import {buttonHover} from '@/style';
import {isBlack} from '@/util/style';
import {Spinner} from '@/components';

const Button = ({secondary, isLoading, children, ...props}) => (
  <Button.Container secondary={secondary} {...props}>
    {children}
    {isLoading && <Button.Spinner $secondary={secondary} />}
  </Button.Container>
);

Button.propTypes = {
  secondary: PropTypes.bool,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Button.Container = styled.button.attrs(({type = 'button', ...props}) => ({
  type,
  ...props,
}))`
  justify-content: center;
  align-items: center;
  width: auto;
  background-color: transparent;
  border-radius: ${p => p.rounded ? '2rem' : p.theme.borderRadius};
  padding: ${p => p.theme.space.half} ${p => p.theme.space.large};
  border: ${p => `1px solid ${p.theme.color.background.on}`};
  font-family: ${p => p.theme.font.alternate};
  font-size: ${p => p.size ? p.theme.fontSize[p.size] : p.theme.fontSize.reading};
  font-weight: ${p => p.theme.fontWeight.normal};
  line-height: ${p => p.theme.lineHeight.xShort};
  color: ${p => p.theme.color.background.on};
  cursor: pointer;

  svg {
    fill: currentColor;
  }

  &:disabled {
    opacity: .5;
  }

  ${p => p.secondary ? css`
    border-color: transparent;
    text-decoration: underline;

    &:hover:enabled {
      background-color: ${isBlack(p.theme.color.background.on) ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.3)'};
      text-decoration: none;
    }
  ` : css`
    &:hover:enabled {
      ${buttonHover(p.theme.color.background.on)}
    }
  `}
`;

Button.Spinner = styled(props => <Spinner {...props} />)`
  fill: none !important;
  margin-left: ${p => p.theme.space.half};
  vertical-align: -2px;

  ${p => !p.$secondary && css`
    ${Button.Container}:hover & {
      fill: none;
      stroke: currentColor;
    }
  `}
`;

export default Button;
