import {useMemo} from 'react';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';

import {useEntities, useEntity} from '@/hooks';

const getDefaultArtistArray = artistArray => (artistArray || []).map(artist => pick(artist, ['id', 'name']));

const useMetadataDefaultValues = (songId) => {
  const song = useEntity('songs', songId);
  const primaryArtists = useEntities('artists', song.primaryArtists);

  return useMemo(() => {
    const defaultValues = pick(song, [
      'title',
      'songArtTextColor',
      'songArtPrimaryColor',
      'songArtSecondaryColor',
      'customPerformances',
    ]);
    defaultValues.songRelationships = song.songRelationships.filter(r => r.songs.length);

    let defaultArtists = pick(song, [
      'featuredArtists',
      'writerArtists',
      'producerArtists',
    ]);
    defaultArtists.primaryArtists = primaryArtists;
    defaultArtists = mapValues(
      defaultArtists,
      getDefaultArtistArray,
    );

    return Object.entries(merge(
      defaultValues,
      defaultArtists,
    )).reduce(
      (result, [key, value]) => ({...result, [snakeCase(key)]: value}),
      {}
    );
  }, [song, primaryArtists]);
};

export default useMetadataDefaultValues;
