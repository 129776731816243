export const setCSSVariable = (name, value, target = document?.documentElement) => {
  if (!target) return;
  target.style.setProperty(name, value);
};

export const removeCSSVariable = (name, target = document?.documentElement) => {
  if (!target) return;
  target.style.removeProperty(name);
};

export const isBlack = hex => (hex === '#000' || hex === '#000000');
