import {useCallback} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import get from 'lodash/get';

export default (schema, ids) => {
  const selectorFn = useCallback(
    state => ids?.map(id => get(state.entities, [schema, id])) ?? [],
    [ids, schema]
  );
  return useSelector(selectorFn, shallowEqual);
};
