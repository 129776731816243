import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import {TextLabel} from '@/components';
import {ALERT_TYPES} from '@/util';

import AlertIcon from '@/svg/alert.svg';
import Checkmark from '@/svg/checkmark.svg';
import Lock from '@/svg/lock.svg';
import X from '@/svg/x.svg';

const ALERT_DETAILS = {
  error: {
    Icon: AlertIcon,
    color: 'error.main',
  },
  success: {
    Icon: Checkmark,
    color: 'success.main',
  },
  warning: {
    Icon: Lock,
    background: 'primary.main',
    color: 'background.on',
  },
};

const Alert = ({status, message, className, onClose}) => {
  const {Icon, color, background} = ALERT_DETAILS[status];

  return (
    <Alert.Container
      color={color}
      background={background}
      className={className}
      role="alert"
    >
      <Alert.Icon color={color}>
        <Icon />
      </Alert.Icon>
      <Alert.TextContainer>
        <Alert.TextLabel
          size="smallReading"
          fontWeight="light"
          color={color}
          textTransform="none"
        >
          {message}
        </Alert.TextLabel>
      </Alert.TextContainer>
      {onClose && (
        <Alert.Icon color={color} onClick={onClose}>
          <X />
        </Alert.Icon>
      )}
    </Alert.Container>
  );
};

Alert.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(ALERT_TYPES).isRequired,
  message: PropTypes.node,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  status: 'error',
};

export default Alert;

Alert.TextContainer = styled.div`
  flex-grow: 1;
  margin: ${p => p.theme.space.quarter} ${p => p.theme.space.small};
`;

Alert.TextLabel = styled(props => <TextLabel {...props} />)`
  line-height: ${p => p.theme.lineHeight.xShort};
`;

Alert.Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${p => p.theme.space.quarter} ${p => p.theme.space.small};
  font-size: ${p => p.theme.fontSize.smallReading};
  color: ${p => get(p.theme.color, p.color)};
  background-color: ${p => get(p.theme.color, p.background, '#fff')};
  border: 1px solid ${p => get(p.theme.color, p.color)};
  justify-content: space-between;
`;

Alert.Icon = styled.button`
  svg {
    fill: ${p => get(p.theme.color, p.color)};
    display: block;
    width: 9px;
    height: 9px;
  }
  ${p => p.onClick && 'cursor: pointer;'}
`;
