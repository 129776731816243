import React from 'react';
import PropTypes from  'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import {TextLabel, PageFooterSocial, PageFooterArtistLinks, PageFooterHotSongLinks} from '@/components';
import {useRedux, FooterGridDesktop} from './PageFooter.shared';
import isFunction from 'lodash/isFunction';
import {useTranslation} from 'react-i18next';
import {surfaceTheme} from '@/style/theme';

const PageFooter = ({className, hideSocialAndSlogan}) => {
  const {t} = useTranslation();
  const {links} = useRedux();
  return (
    <ThemeProvider theme={surfaceTheme}>
      <PageFooter.Container className={className}>
        <PageFooter.Section>
          <PageFooter.Half>
            {hideSocialAndSlogan || <PageFooterSocial />}
          </PageFooter.Half>

          {links.map((section, i) =>
            <PageFooter.Quarter key={i}>
              {section.map(
                ({label, action, className: linkClassName}) => isFunction(action) ?
                  <PageFooter.Link onClick={action} key={label} className={linkClassName}>{t(`page_footer.section.${label}`)}</PageFooter.Link> :
                  <PageFooter.Link as="a" href={action} key={label} rel="noopener" className={linkClassName}>{t(`page_footer.section.${label}`)}</PageFooter.Link>
              )}
            </PageFooter.Quarter>
          )}
          <PageFooter.OffsetQuarter>
            <PageFooter.FinePrint>
              {`© ${new Date().getFullYear()} ${t('page_footer.fine_print.company')}`}
            </PageFooter.FinePrint>
          </PageFooter.OffsetQuarter>
          <PageFooter.Quarter>
            <PageFooter.FinePrint as="a" href="/static/terms">
              {t('page_footer.fine_print.terms')}
            </PageFooter.FinePrint>
          </PageFooter.Quarter>
        </PageFooter.Section>

        <PageFooter.Bottom>
          <PageFooter.Row>
            <PageFooter.VerifiedArtists href="/verified-artists">
              <TextLabel size="smallReading">{t('role.types.verified_artist.other')}</TextLabel>
            </PageFooter.VerifiedArtists>
            <PageFooter.FlexWrap>
              <PageFooter.Label>
                <TextLabel size="smallReading">{t('page_footer.all_artists')}</TextLabel>
              </PageFooter.Label>
              <PageFooterArtistLinks />
            </PageFooter.FlexWrap>
          </PageFooter.Row>

          <PageFooter.Row>
            <PageFooter.Label>
              <TextLabel size="smallReading">{t('page_footer.hot_songs.title')}</TextLabel>
            </PageFooter.Label>
            <PageFooter.FlexWrap>
              <PageFooterHotSongLinks />
            </PageFooter.FlexWrap>
          </PageFooter.Row>
        </PageFooter.Bottom>
      </PageFooter.Container>
    </ThemeProvider>
  );
};

PageFooter.propTypes = {
  className: PropTypes.string,
  hideSocialAndSlogan: PropTypes.bool,
};

PageFooter.defaultProps = {
  hideSocialAndSlogan: false,
};

export default React.memo(PageFooter);

PageFooter.Container = styled.footer`
  background-color: ${p => p.theme.color.background.main};
`;

PageFooter.Link = styled.div`
  display: block;
  color: inherit;
  font-weight: 100;
  cursor: pointer;
`;

PageFooter.Section = styled(FooterGridDesktop)`
  color: ${p => p.theme.color.background.on};
  padding: ${p => p.theme.space.xxxLarge} 60px ${p => p.theme.space.xxLarge};

  ${PageFooter.Link} + ${PageFooter.Link} {
    margin-top: ${p => p.theme.space.full};
  }
`;

PageFooter.Half = styled.div`
  grid-column: span 6;
`;

PageFooter.Quarter = styled.div`
  grid-column: span 3;
`;

PageFooter.OffsetQuarter = styled(PageFooter.Quarter)`
  grid-column: 7 / span 3;
`;

PageFooter.FinePrint = styled.div`
  display: block;
  color: ${p => p.theme.color.background.onVariant};
  font-weight: 100;
  font-size: ${p => p.theme.fontSize.xSmallReading};
`;

PageFooter.Bottom = styled(PageFooter.Section)`
  border-top: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
  padding-top: ${p => p.theme.space.xxLarge};
  padding-bottom: ${p => p.theme.space.xxLarge};
`;

PageFooter.Row = styled.div`
  display: flex;
  grid-column: 1 / -1;
  align-items: baseline;
`;

PageFooter.FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

PageFooter.VerifiedArtists = styled.a`
  color: inherit;

  &:after {
    content: '•';
    margin: 0 ${p => p.theme.space.full};
  }
`;

PageFooter.Label = styled.div`
  margin-right: ${p => p.theme.space.full};
`;
